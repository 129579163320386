<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Add Place</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="12">
        <vs-row vs-w="12">
          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Category Place</span>
            <v-select
              label="name"
              :options="placeCategoryOptions"
              :reduce="category => category.id"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="payload.place_category_id"
            />
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              label="Place Name (*)"
              v-model="payload.name"
            />
            <span class="block text-sm mt-2 text-danger">{{ errors.first('name') }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mt-3">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button class="mr-3 mb-2">Submit</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from '@/axios'
import vSelect from 'vue-select'

// Store Module
import modulePlaceManagement from "@/store/place-management/modulePlaceManagement.js";

export default {
  name: "PlaceAdd",

  metaInfo: {
    title: "Add Place",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  components: {
    "v-select": vSelect
  },

  data: () => ({
    payload: {
      place_category_id: "",
      name: ""
    },
    placeCategoryOptions: []
  }),

  methods: {
    getPlaceCategories() {
      axios
        .get("/place-category")
        .then(({ data: res }) => {
          this.placeCategoryOptions = res.data.map(category => {
            return {
              id: category.id,
              name: category.name
            }
          });
        })
        .catch(err => this.$catchErrorResponse(err));
    },

    storeData() {
      const formData = new FormData();
      // set formData
      formData.set('place_category_id', this.payload.place_category_id)
      formData.set("name", this.payload.name);
      if (this.payload.icon !== "") {
        formData.set("icon", this.payload.icon);
      }

      this.$store
        .dispatch("placeManagement/storePlace", formData)
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push("/places");
        })
        .catch(err => {
          this.$catchErrorResponse(err)
        });
    }
  },

  mounted() {
    if (this.url == null) {
      this.url = "https://via.placeholder.com/100";
    }
  },

  created() {
    if (!modulePlaceManagement.isRegistered) {
      this.$store.registerModule("placeManagement", modulePlaceManagement);
      modulePlaceManagement.isRegistered = true;
    }

    this.getPlaceCategories()
  }
};
</script>

<style></style>
